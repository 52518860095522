import { useState } from "react";
import { useTranslation } from "react-i18next";

import ModalLayout from "../modalLayout/ModalLayout";
import Spinner from "../spinner/Spinner";
import { useSimpleModal } from "../modalContext/SimpleModalContext";
import "./MsgModal.scss";

export default function ConfirmMsgModal({ isOpen, msg, btnClass, btnText, iconClass, noAutoDisable, callback }) {
  console.log("ConfirmMsgModal");

  const { t } = useTranslation("common");
  const modal = useSimpleModal();

  const [disabled, setDisabled] = useState(false);

  function submit() {
    if (!noAutoDisable) {
      setDisabled(true);
    }
    callback();
  }

  return (
    <ModalLayout isOpen={isOpen} modalContext={modal} modalContentClassName="p-4" modalClassName={"msgModal"}>
      <div className="pb-3">
        <div className={iconClass + " icon p-4"}></div>
      </div>
      <div className="text-center">
        <div className="greyestFont big bold">{msg}</div>
      </div>
      <div className="text-center pt-4">
        <button className="btn bold white withBorder w6 me-5" disabled={disabled} onClick={modal.close}>
          {t("lbl.cancel")}
        </button>
        <button className={`btn bold w6 ${btnClass}`} disabled={disabled} onClick={submit}>
          {btnText}
        </button>
      </div>
    </ModalLayout>
  );
}
