import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { findIndex } from "lodash";
import { useUser } from "../../../components/userContext/UserContext";
import WidgetInitCreate from "./widgets/WidgetInitCreate";
import { useMutation, useQuery } from "@apollo/client";
import html2Canvas from "html2canvas-pro";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Select, Form, Spin, Empty } from "antd";
import { layoutRearrangement, PAGE_LAYOUT_PARAMETER } from "./Constants.js";
import { PAGE_SELECTORS_OF_PERIOD, UNIT_METRIC_BY_SOURCE } from "../../insightsPage/common";
import { CloseOutlined } from "@ant-design/icons";
import { getData, useMutationErrHandler, useErrorHandler, getSDashboardsByUserDefault, resizeSDashboardWidget } from "../../../Gql";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./dashboardPage.scss";
import "../../../antdStyleRewrite.scss";
import { v4 as uuidv4 } from "uuid";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default function DashboardResponsiveReactGrid() {
  const { t } = useTranslation("common");
  const { userRes } = useUser();
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState();
  const [refresh, setRefresh] = useState(false);
  const [isDraggable, setDraggable] = useState(false);

  const getSDashboardsByUserDefaultQuery = useQuery(getSDashboardsByUserDefault, {
    variables: { id: userRes?.data?.id },
    skip: !userRes?.data?.tenant?.id
  });

  const dashboardsByUserDefaultData = getData(getSDashboardsByUserDefaultQuery);

  const error = useErrorHandler(getSDashboardsByUserDefaultQuery);

  const [resizeSDashboardWidgetAction] = useMutation(resizeSDashboardWidget);
  const errorMutationHandler = useMutationErrHandler();

  useEffect(() => {
    if (dashboardsByUserDefaultData?.widgets) {
      let regroupWidgets = dashboardsByUserDefaultData.widgets;

      regroupWidgets = regroupWidgets.map(item => {
        let minSize = PAGE_LAYOUT_PARAMETER.WIDGET_ENFORCED_MINSIZE[item.visualType];
        let tempItem = {
          ...item,
          widgetName: item.visualType,
          layout: {
            id: item.id,
            x: item.positionX,
            y: item.positionY,
            w: item.width,
            h: item.height,
            ...minSize,
            static: false
          }
        };
        return tempItem;
      });
      setWidgets([...regroupWidgets]);
      setLoading(false);
    }
  }, [dashboardsByUserDefaultData]);

  useEffect(() => {
    if (error) setLoading(false);
  }, [error]);

  const handleLayouts = () => {
    return widgets.map(item => item.layout);
  };

  const handleSelectChange = (key, value) => {
    if (key == "period") setPeriod(value);
  };
  const handlePeriodReset = () => {
    setPeriod(undefined);
  };
  const onLayoutChange = newLayout => {};

  const handleLayoutChange = (layouts, oldItem, newItem) => {
    if (JSON.stringify(oldItem) === JSON.stringify(newItem)) {
      return;
    }
    for (const layout of layouts) {
      const updateIndex = findIndex(widgets, w => w.layout.id == layout.i);
      if (updateIndex !== -1) {
        let updateWidget = widgets[updateIndex];
        updateWidget = { ...updateWidget, width: layout.w, height: layout.h, layout: { ...layout, id: layout.i } };
        widgets.splice(updateIndex, 1, { ...updateWidget });
      }
    }

    const newWidgets = [...widgets];
    let list = layouts.map(item => {
      let newItem = {
        id: item.i,
        width: item.w,
        height: item.h,
        positionX: item.x,
        positionY: item.y
      };
      return newItem;
    });
    // widget resize and relocate
    resizeSDashboardWidgetAction({ variables: { list: list } }).then(res => {
      setWidgets(newWidgets);
      setDraggable(false);
    }, errorMutationHandler);
  };
  const domToCanvasExport = id => {
    html2Canvas(document.querySelectorAll(".widget" + id)[0], { timeout: 2000, scale: 2.82 }).then(canvas => {
      // 将canvas转换为DataURL
      const imgData = canvas.toDataURL("image/png");
      // 创建一个隐藏的a标签用于下载
      const downloadLink = document.createElement("a");
      downloadLink.href = imgData;
      downloadLink.download = new Date().getTime() + ".png" || "exported-image.png"; // 设置下载文件的名称
      downloadLink.style.display = "none"; // 隐藏a标签
      // 将a标签添加到DOM中
      document.body.appendChild(downloadLink);
      // 模拟点击a标签以开始下载
      downloadLink.click();
      // 清理，从DOM中移除a标签
      downloadLink.remove();
      // 如果需要，你可以在这里将imgData发送到服务器或进行其他操作
    });
  };
  const handleUnMoveWidget = () => {
    setDraggable(false);
  };
  const handleRefresh = () => {
    if (refresh) {
      setRefresh(false);
      let timer = setTimeout(() => {
        window.clearTimeout(timer);
        setRefresh(true);
      }, 500);
    } else {
      setRefresh(true);
    }
  };

  return (
    <div className="dashboard-portal-container">
      <div className="page-container-header flex-nowrap">
        <div className="bigger bold mainFont">{userRes?.data?.tenant?.name?.toUpperCase()}</div>
        <div className="mainFont header-right-option">
          <Form.Item key="period" name="period" style={{ marginRight: 10 }} className={`fontInter ant-form-item-period ${period ? "ant-form-item-period-active" : ""}`}>
            <Select
              suffixIcon={<div className={`icon insights expandIcon`}></div>}
              placeholder={t("insightsPage.period")}
              value={period}
              onChange={e => handleSelectChange("period", e)}
              size={"small"}
            >
              {PAGE_SELECTORS_OF_PERIOD.map(({ label, title, options }) => {
                return (
                  <Select.OptGroup key={uuidv4() + label} label={label} title={title}>
                    {options.map(child => (
                      <Select.Option key={uuidv4() + child.label} value={child.value}>
                        {t("dashboardPage.period." + [child.label])}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                );
              })}
            </Select>
            {period ? (
              <div className="clear-box" onClick={handlePeriodReset}>
                <CloseOutlined className="yellowFont" />
              </div>
            ) : null}
          </Form.Item>
          <Form.Item key="refresh" name="Auto Refresh" style={{ marginRight: 0 }} className={`fontInter`}>
            <div className={"greyestFont  icon insights refresh ps-25 pe-1"} title={"refresh"} onClick={handleRefresh}></div>
            {/*<Select*/}
            {/*  suffixIcon={<div className={`icon insights expandIcon`}></div>}*/}
            {/*  placeholder={t("insightsPage.refresh")}*/}
            {/*  value={period}*/}
            {/*  onChange={e => handleSelectChange("refresh", e)}*/}
            {/*  size={"small"}*/}
            {/*  allowClear>*/}
            {/*  {PAGE_AUTO_REFRESH.map(({ label,value,seconds }) => {*/}
            {/*    return (*/}
            {/*        <Select.Option key={uuidv4() + label} value={seconds}>*/}
            {/*          {t("dashboardPage.refresh." + [label])}*/}
            {/*        </Select.Option>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</Select>*/}
          </Form.Item>
        </div>
      </div>
      {widgets.length ? (
        <>
          <ResponsiveReactGridLayout
            layouts={handleLayouts()}
            autoSize={true}
            verticalCompact={true}
            onLayoutChange={onLayoutChange}
            onResizeStop={handleLayoutChange}
            onDragStop={handleLayoutChange}
            useCSSTransforms={false}
            {...PAGE_LAYOUT_PARAMETER.LAYOUT_RESPONSIVE_GRID}
          >
            {widgets.map((widget, index) => {
              let { default_metric } = JSON.parse(widget?.parameters);
              let unit = UNIT_METRIC_BY_SOURCE(widget.type, default_metric || null);
              let extraClassName = (widget.visualType.toLowerCase().indexOf("heatmap") != -1 ? "dashboard-widget-heatmap " : "") + " ";
              extraClassName += (widget.visualType.toLowerCase().indexOf("weather") != -1 ? "dashboard-widget-weather " : "") + " ";
              return (
                <div
                  className={extraClassName + "dashboard-widget-wrapper " + "widget" + widget.layout.id}
                  id={`widget${widget.id}`}
                  key={widget.layout.id}
                  data-grid={{ ...widget.layout, isDraggable: isDraggable }}
                  onMouseLeave={e => handleUnMoveWidget(e, widget)}
                >
                  <WidgetInitCreate
                    setDraggable={setDraggable}
                    domToCanvasExport={domToCanvasExport}
                    key={widget.id}
                    widget={{ ...widget, refresh, params: { period }, unit: unit }}
                    params={{ period }}
                  />
                </div>
              );
            })}
          </ResponsiveReactGridLayout>
          <div style={{ height: "40px" }}></div>
        </>
      ) : loading ? (
        <Spin spinning={loading}></Spin>
      ) : (
        <Empty description={false} />
      )}
    </div>
  );
}
