import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Dropdown from "../dropdown/Dropdown";
import Spinner from "../spinner/Spinner";

//the building and floor dropdown
//onChange(zone)
export default function FloorList({ onChange, buildingsRes, defaultZoneId }) {
  console.log("FloorList");

  const { t } = useTranslation("common");

  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);

  function handleChangeZone(selected) {
    if (selected) {
      if (selectedZone !== selected) {
        setSelectedZone(selected);
        onChange(selected);
      }
    } else {
      setSelectedZone(null);
      onChange(null);
    }
  }

  function selectBuildingAndZone(building, zone) {
    setSelectedBuilding(building);
    handleChangeZone(zone);
  }
  function handleChangeBuilding(selected) {
    if (selected) {
      if (selectedBuilding !== selected) {
        selectBuildingAndZone(selected, selected.zones[0]);
      }
    } else {
      selectBuildingAndZone(null, null);
    }
  }

  const buildings = useMemo(() => {
    return buildingsRes.data ? buildingsRes.data.filter(b => b.zones.length > 0) : null;
  }, [buildingsRes.data]);

  //init selection
  useEffect(() => {
    if (buildings && buildings.length) {
      //select curWs's building/zone
      if (defaultZoneId) {
        for (let i = 0; i < buildings.length; i++) {
          let zone = buildings[i].zones.find(z => z.id === defaultZoneId);
          if (zone) {
            selectBuildingAndZone(buildings[i], zone);
            return;
          }
        }
      }
      //select the first building/zone
      selectBuildingAndZone(buildings[0], buildings[0].zones[0]);
    } else {
      //no default selection
      selectBuildingAndZone(null, null);
    }
  }, [buildings, defaultZoneId]);

  if (buildingsRes.loading) {
    return <Spinner isCenter={true} />;
  } else if (buildings) {
    if (buildings.length == 1 && buildings[0].zones.length == 1) {
      return "";
    } else {
      return (
        <div className="floorList row small">
          <div className="col-md-6 col-12 mb-md-0 mb-3">
            <div className="row align-items-center flex-nowrap">
              <div className="col-auto bold d-none d-md-block">{t("lbl.building")}</div>
              <div className="col minw4">
                <Dropdown
                  valueObj={selectedBuilding}
                  options={buildings}
                  disabled={!buildings || buildings.length == 0}
                  onChange={handleChangeBuilding}
                  handleColor="yellow"
                  className="main yellowBorder" //round?
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="row align-items-center flex-nowrap">
              <div className="col-auto bold d-none d-md-block">{t("lbl.floor")}</div>
              <div className="col minw4">
                <Dropdown
                  valueObj={selectedZone}
                  options={selectedBuilding ? selectedBuilding.zones : []}
                  disabled={!buildings || buildings.length == 0}
                  onChange={handleChangeZone}
                  handleColor="yellow"
                  className="main yellowBorder" //round?
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return "";
  }
}
