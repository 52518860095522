import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useNode, useNodes } from "../../../Gql";
import ModalLayout from "../../modalLayout/ModalLayout";
import SearchBox from "../../searchBox/SearchBox";
import FloorList from "../../floorList/FloorList";
import Dropdown from "../../dropdown/Dropdown";
import MapToggle from "../../mapToggle/MapToggle";
import FloorActionPanel from "../floorActionPanel/FloorActionPanel";
import FloorPlan from "../../floorPlan/FloorPlan";
import ActionPanel from "../actionPanel/ActionPanel";
import NodeRow from "../nodeRow/NodeRow";
import Spinner from "../../spinner/Spinner";
import { useModal } from "../../modalContext/ModalContext";
import { LIST_TYPE, DEFAULT_TEMP_RANGE, ACL_ROLE } from "../../../Constants";
import { Utils } from "../../../Utils";
import "./ControlOthersModal.scss";

export default function ControlOthersModal({ isOpen, listType, userRes, wsRes, zoneMapRes, buildingsRes, resolve, reject }) {
  console.log("ControlOthersModal", listType);
  if (userRes?.data == null || buildingsRes?.data == null || zoneMapRes?.data == null) {
    console.error("must loaded userRes, zoneMapRes and buildingsRes before opening this popup");
  }

  const { t } = useTranslation("common");
  const modal = useModal();

  let items = [
    { id: LIST_TYPE.fixdesk, name: t("nodeType.WORKSTATION") },
    { id: LIST_TYPE.hotdesk, name: t("nodeType.HOTDESK") },
    { id: LIST_TYPE.room, name: t("nodeType.ROOM") }
  ];
  if (Utils.hasMinAclRole(userRes.data, ACL_ROLE.zoneAdmin)) {
    items.push({ id: null, name: t("nodeType.FLOOR") });
  }

  const [searchText, setSearchText] = useState("");
  const [isByMap, setIsByMap] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedListTypeObj, setSelectedListTypeObj] = useState(items.find(i => i.id == listType) || items[0]);

  //true: action made
  //false: no action made
  //null: waiting for resolve (since handleToggleNode may open TimerModal)
  const [controlActionMade, setControlActionMade] = useState(false);

  const nodesRes = useNodes(selectedZone ? selectedZone.id : null, selectedListTypeObj.id, true, true);
  const filteredList = useMemo(() => {
    let list = [];
    if (nodesRes.data) {
      list = nodesRes.data;
      if (searchText) {
        list = list.filter(n => n.name.toLowerCase().includes(searchText));
      }
    }
    return list;
  }, [nodesRes, searchText]);

  const nodeRes = useNode(selectedNodeId, selectedListTypeObj.id);

  function handleSelectNode(node) {
    if (node.id === selectedNodeId) {
      setSelectedNodeId(null);
    } else {
      setSelectedNodeId(node.id);
    }
  }

  function handleSetIsByMap(byMap) {
    setSelectedNodeId(null);
    setIsByMap(byMap);
  }

  function handleSelectZone(zone) {
    setSelectedNodeId(null);
    setSelectedZone(zone);
  }

  function handleChangeType(typeObj) {
    setSelectedNodeId(null);
    setSelectedListTypeObj(typeObj);
    if (!typeObj.id) {
      setIsByMap(false);
    }
  }

  function handleAfterClose() {
    if (resolve && controlActionMade !== null) {
      resolve(controlActionMade);
    }
  }

  return (
    <ModalLayout isOpen={isOpen} modalContext={modal} modalContentClassName="p-3" modalClassName={"controlOthersModal"} onAfterClose={handleAfterClose}>
      <div className="row align-items-center mx-0 pb-3 underline greyerBorder">
        <div className="col ps-0 mainFont">
          <div className="bold big uppercase">{t("lbl.controlOther")}</div>
        </div>
        {userRes.data && userRes.data.tenant.enableFloorPlanSelection && selectedListTypeObj.id && (
          <>
            <div className="col-auto">
              <SearchBox className="yellowBorder w8 focusW18" onChange={t => setSearchText(t)} />
            </div>
            <MapToggle className="col-auto" isMap={isByMap} onChange={handleSetIsByMap} />
          </>
        )}
        <div className="col-auto pe-0">
          <button className="btn icon crossThin p-3" onClick={modal.close}></button>
        </div>
      </div>
      <div className="row align-items-center my-3">
        <div className="col col-xl-8">
          <FloorList onChange={handleSelectZone} buildingsRes={buildingsRes} defaultZoneId={wsRes?.data?.zoneId} />
        </div>
        {selectedZone && (
          <div className="col-lg-auto col-12 col-lg-auto mt-3 mt-lg-0">
            <div className="row align-items-center">
              <div className="col-auto d-none d-md-block bold">{t("lbl.type")}</div>
              <div className="col-12 col-md-auto minw12">
                <Dropdown valueObj={selectedListTypeObj} options={items} onChange={handleChangeType} handleColor="yellow" className="main yellowBorder capital" />
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedZone &&
        (selectedListTypeObj.id ? (
          isByMap ? (
            <div className="d-flex flex-column flex-lg-row flex-fill-remain">
              <div className="flex-fill-remain floorPlanContainer">
                <FloorPlan
                  zone={selectedZone}
                  nodes={nodesRes.loading ? null : filteredList}
                  coolTemp={DEFAULT_TEMP_RANGE.cool}
                  warmTemp={DEFAULT_TEMP_RANGE.warm}
                  onClick={handleSelectNode}
                />
              </div>
              {selectedNodeId && (
                <div className="floorPlanActionPanel align-self-lg-stretch ms-0 ms-lg-3 mt-3 mt-lg-0">
                  <div className="mainCard px-35 pt-35 h-100">
                    <ActionPanel
                      nodeRes={nodeRes}
                      zoneMapRes={zoneMapRes}
                      userRes={userRes}
                      isRm={selectedListTypeObj.id === LIST_TYPE.room}
                      setControlActionMade={setControlActionMade}
                      resolve={resolve}
                      reject={reject}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : nodesRes.loading ? (
            <Spinner isCenter={true} height={"4rem"} />
          ) : (
            <div className="overflow-y-auto p-1">
              {filteredList.map(n => (
                <div className="fullBorder" key={n.id}>
                  <NodeRow node={n} listType={selectedListTypeObj.id} onClick={handleSelectNode} />
                  {n.id === selectedNodeId && nodeRes && (
                    <div className="mainCard px-35 pt-35">
                      <ActionPanel
                        nodeRes={nodeRes}
                        zoneMapRes={zoneMapRes}
                        userRes={userRes}
                        isRm={selectedListTypeObj.id === LIST_TYPE.room}
                        isBgDark={true}
                        setControlActionMade={setControlActionMade}
                        resolve={resolve}
                        reject={reject}
                        hideTitle={true}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )
        ) : (
          <FloorActionPanel zone={selectedZone} />
        ))}
    </ModalLayout>
  );
}
